import React from "react";
import { styled } from "@mui/system";
import { Link, useNavigate } from "react-router-dom";

import HeaderExternal from "../../components/header/HeaderExternal";
import { Typography } from "@mui/material";
import Footer from "../../components/footer/FooterComponent";

export default function PrivacyPolicyPage() {
    const navigate = useNavigate();    

    return (
        <LayoutWrapper>
            <div style={{width: '100%', position: 'fixed', top: 0, left: 0, height: '75px', zIndex: 9999,}}>
                <HeaderExternal />
            </div>
            <div className="content">
                <h1 style={{textAlign: 'center'}}>Privacy Policy</h1>
                <p>
                    Thank you for using the NOUN eLink App ("the App") developed by [Intradot Limited]. This Privacy Policy is designed to help you understand how we collect, use, and safeguard your personal information. By using the App, you agree to the terms outlined in this policy.
                </p>
                <h2>User Data Collection and Usage</h2>
                <p>Our app is committed to protecting your privacy and ensures that all user data is handled in compliance with applicable privacy laws and Google Play Store policies.</p>
                <h3>Data Collected</h3>
                <p>We only collect the following user data when you create an account in our app:</p>
                <ul>
                    <li><b>Name:</b> To personalize your user profile</li>
                    <li><b>Email Address:</b> Used for account creation, login, and account recovery.</li>
                    <li><b>Phone Number:</b> For account verification and, if necessary, user support.</li>
                    <li><b>Password:</b> Used to secure your account (stored in an encrypted format).</li>
                    <li><b>Matriculation Number:</b> Collected for account identification and verification purposes specific to our services.</li>
                </ul>

                <h3>Purpose of Data Collection</h3>

                <p>The information we collect is essential to provide and maintain core app functionality, including:</p>
                <ul>
                    <li><b>Account Management:</b> To create and maintain your user profile.</li>
                    <li><b>Authentication and Security:</b> To ensure only authorized access to your account.</li>
                    <li><b>User Support:</b> To provide assistance and address any issues or inquiries you may have.</li>
                </ul>
                <h3>Data Security</h3>

                <p>We prioritize the security of your data and implement industry-standard measures to protect your personal information. This includes data encryption, access controls, and secure storage practices. Passwords are stored in an encrypted format, ensuring additional security.</p>

                <h3>Data Sharing</h3>

                <p>We do not share, sell, or disclose your personal information with third parties, except when required by law or with your explicit consent.</p>

                <h3>Data Retention</h3>

                <p>Your data is retained for as long as your account remains active. If you choose to delete your account, we will remove your personal data in accordance with our data retention policy and legal obligations.</p>

                <h3>User Rights</h3>

                <p>You have the right to access, modify, or request deletion of your personal information at any time. For assistance, please contact our support team at <Link to="mailto:support@intradot.com.ng" className="link">support@intradot.com.ng</Link>.</p>

                <p>By using our app, you agree to this data collection and handling process as described in this policy.</p>

                <h2>Third-Party Services:</h2>
                
                <p>he App may integrate with third-party services for certain features. Please be aware that these services have their own privacy policies, and we encourage you to review them.</p>
                
                <h2>Your Choices:</h2>
                
                <p>You can manage your account settings and preferences within the App.</p>
                <p>You have the right to opt-out of promotional communications by following the instructions in the communication.</p>
                <p>You have the right to delete your registered account.</p>
                
                <h2>Changes to this Privacy Policy:</h2>
                
                <p>We reserve the right to update this Privacy Policy to reflect changes in our practices or applicable laws. We will notify you of any material changes through the App or other means.</p>
                
                <h2>Contact Us:</h2>
                
                <p>If you have any questions or concerns about this Privacy Policy or the App, please contact us at <Link to="mailto:info@intradot.com.ng" className="link">info@intradot.com.ng</Link></p>
                
                <p>By using the NOUN eLink mobile app, you agree to the terms outlined in this Privacy Policy.</p>
                                
            </div>
            <div style={{position: 'relative', top: '75px'}}>
                <Footer />
            </div>
            
        </LayoutWrapper>
    )
}

const LayoutWrapper = styled('div')(() => ({
    height: 'auto',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    '& .content': {
        position: 'relative',
        top: '75px',
        padding: '30px',
        paddingLeft: '5%',
        paddingRight: '5%',
        boxSizing: 'border-box',
        '& h2': {
            fontSize: '20px',
            lineHeight: '30px',
            marginTop: '50px',
        },
        '& .h3': {
            fontSize: '16px',
            lineHeight: '24px',
        },
        '& p': {
            fontSize: '16px',
            lineHeight: '24px',
        }
    },
}));

